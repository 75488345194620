import './App.css';
import fiaraRoxo from './img/fiara-roxo.png';
// import portifolio1 from './img/portfolio/portfolio-1.jpg'
// import portifolio2 from './img/portfolio/portfolio-2.jpg'
// import portifolio3 from './img/portfolio/portfolio-3.jpg'
import portifolio4 from './img/portfolio/portfolio-4.jpg'
// import portifolio5 from './img/portfolio/portfolio-5.jpg'
// import portifolio6 from './img/portfolio/portfolio-6.jpg'
// import portifolio7 from './img/portfolio/portfolio-7.jpg'
// import portifolio8 from './img/portfolio/portfolio-8.jpg'
// import portifolio9 from './img/portfolio/portfolio-9.jpg'

import parceiro1 from './img/clients/client-1.png'
import parceiro2 from './img/clients/client-2.png'
import parceiro3 from './img/clients/client-3.png'
import parceiro4 from './img/clients/client-4.png'
import parceiro5 from './img/clients/client-5.png'
import parceiro6 from './img/clients/client-6.png'
import parceiro7 from './img/clients/client-7.png'
import parceiro8 from './img/clients/client-8.png'
import parceiro9 from './img/clients/client-9.png'
import parceiro10 from './img/clients/client-10.png'
import { PureIncrement } from 'pure_counter';


function App() {
  return (
    <>
      {/* ======= Header ======= */}
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <h1 className="logo me-auto">
            <a href="#hero">
              <img
                src={fiaraRoxo}
                alt="Logo Fiara"
                className="img-fluid"
              />
            </a>
          </h1>
          {/* Uncomment below if you prefer to use an image logo */}
          {/* <a href="index.html" class="logo me-auto"><img src="/img/logo.png" alt=""></a>*/}
          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Início
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  Quero ser uma Estrela
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#services">
                  Serviços
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="#portfolio">
                  Atendimentos
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="#faq">
                  Dúvidas
                </a>
              </li>
              {/*<li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
                <ul>
                    <li><a href="#">Drop Down 1</a></li>
                    <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                    <ul>
                        <li><a href="#">Deep Drop Down 1</a></li>
                        <li><a href="#">Deep Drop Down 2</a></li>
                        <li><a href="#">Deep Drop Down 3</a></li>
                        <li><a href="#">Deep Drop Down 4</a></li>
                        <li><a href="#">Deep Drop Down 5</a></li>
                    </ul>
                    </li>
                    <li><a href="#">Drop Down 2</a></li>
                    <li><a href="#">Drop Down 3</a></li>
                    <li><a href="#">Drop Down 4</a></li>
                </ul>
                </li>
                <li><a class="nav-link scrollto" href="#contact">Contato</a></li>*/}
            </ul>
            <i className="bi bi-list mobile-nav-toggle" />
          </nav>
          {/* .navbar */}
          <a
            href="https://wa.me/5549989048131"
            target="_blank"
            className="get-started-btn scrollto"
          >
            Agendar Agora
          </a>
        </div>
      </header>
      {/* End Header */}
      {/* ======= Hero Section ======= */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-delay={100}>
          <div className="row">
            <div className="col-xl-6">
              <h1>Serviços de beleza e bem-estar onde você estiver.</h1>
              <h2>
                Receba nossos serviços no formato delivery, agende agora mesmo!
                Atendimento em Chapecó/SC
              </h2>
              <a
                href="https://wa.me/5549989048131"
                target="_blank"
                className="btn-get-started scrollto"
              >
                Agendar Agora
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero */}
      <main id="main">
        {/* ======= Counts Section ======= */}
        <section id="counts" className="counts">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                {/*<div class="count-box">
       <i class="bi bi-emoji-smile"></i>
          <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
          <p>Clientes Satisfeitos</p> 
      </div>*/}
              </div>
              <div className="col-lg-4 col-md-4 mt-5 mt-lg-0">
                <div className="count-box">
                  <i className="bi bi-people" />
                  <PureIncrement start={0} end={30} duration={1} className="purecounter" />
                  <p>Estrelas Fiara</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mt-5 mt-md-0">
                {/*<div class="count-box">
          <i class="bi bi-journal-richtext"></i>
          <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
          <p>Total de Agendamentos</p>
      </div>*/}
              </div>
            </div>
          </div>
        </section>
        {/* End Counts Section */}
        {/* ======= Services Section ======= */}
        <section id="services" className="services section-bg ">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Serviços</h2>
              <p>Confira nossos serviços disponíveis para delivery</p>
            </div>
            <div className="row">
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={600}
                >
                  <i className="bi bi-scissors" />
                  <h4>
                    <a href="#">Unhas</a>
                  </h4>
                  <p>
                    Experiência de serviços de manicure, pedicure e podóloga, sem
                    precisar sair de casa.{" "}
                  </p>
                  <p>
                    Nossos serviços: esmaltação simples, esmaltação em gel,
                    blindagem.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <i className="ri-brush-line" />
                  <h4>
                    <a href="#">Maquiagem</a>
                  </h4>
                  <p>
                    Serviços de maquiagem profissional diretamente em sua casa,
                    proporcionando praticidade, qualidade e um toque de glamour para
                    qualquer ocasião.
                  </p>
                  <p>
                    Nossos serviços: maquiagem simples/básica, social e formatura.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <i className="ri-hand" />
                  <h4>
                    <a href="#">Massagem</a>
                  </h4>
                  <p>
                    Alívio do estresse, relaxamento muscular e renovação da energia,
                    sem precisar sair de casa.
                  </p>
                  <p>
                    Nossos serviços: massagem relaxante, terapêutica, drenagem
                    linfática.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="ri-tailwind-css-fill" />
                  <h4>
                    <a href="#">Cabelos</a>
                  </h4>
                  <p>
                    Serviços de escova e cuidados capilares entregues onde estiver.
                  </p>
                  <p>Nossos serviços: corte e escova.</p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <i className="bi bi-box-seam" />
                  <h4>
                    <a href="#">Estética</a>
                  </h4>
                  <p>
                    Transforme sua rotina de cuidados com a pele e o corpo com
                    nossos serviços de estética personalizados, entregues
                    diretamente em sua casa.
                  </p>
                  <p>Nossos serviços: limpeza de pele.</p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <i className="bi bi-magic" />
                  <h4>
                    <a href="#">Sobrancelha</a>
                  </h4>
                  <p>
                    {" "}
                    Revele sua beleza natural com sobrancelhas moldadas, sem sair de
                    casa, com nossos serviços de design e cuidado das sobrancelhas.
                  </p>
                  <p>
                    Nossos serviços: sobrancelha fio a fio, micropigmentação, henna,
                    mapeamento geométrico
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <i className="ri-sparkling-2-fill" />
                  <h4>
                    <a href="#">Depilação</a>
                  </h4>
                  <p>
                    {" "}
                    Liberte-se das preocupações com a depilação e experimente a
                    suavidade e a praticidade de nossos serviços em casa.
                  </p>
                  <p>Nossos serviços: depilação em cera</p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <i className="ri-body-scan-fill" />
                  <h4>
                    <a href="#">Aplicação de bandagem</a>
                  </h4>
                  <p>
                    Recupere-se mais rápido e com conforto. Aplicação de bandagem
                    terapêutica no conforto da sua casa, proporcionando suporte e
                    alívio para dores musculares e articulares, para que você possa
                    voltar às atividades rapidamente.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <i className="ri-first-aid-kit-fill" />
                  <h4>
                    <a href="#">Aferição de pressão arterial</a>
                  </h4>
                  <p>Cuide da sua saúde de forma conveniente e confiável.</p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <i className="ri-syringe-line" />
                  <h4>
                    <a href="#">Drenagem pós operatório</a>
                  </h4>
                  <p>
                    Facilite sua recuperação pós-operatória com nossa drenagem em
                    casa.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div
                  className="icon-box  box-service"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <i className="ri-user-heart-line" />
                  <h4>
                    <a href="#">Fisioterapia</a>
                  </h4>
                  <p>
                    Serviços de fisioterapia personalizados diretamente em seu lar,
                    com profissionais especializados que se dedicam ao seu
                    progresso, para que você retome suas atividades diárias com
                    confiança e conforto.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Services Section */}
        {/* ======= Portfolio Section ======= */}
        <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Nosso Atendimentos</h2>
              <p>
                Aqui estão alguns de nossos atendimentos feitos pelas Estrelas
                Fiara.
              </p>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay={100}>
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">
                    Tudo
                  </li>
                  <li data-filter=".filter-app">Cabelo</li>
                  <li data-filter=".filter-card">Unha</li>
                  <li data-filter=".filter-web">Maquiagem</li>
                </ul>
              </div>
            </div>
            <div
              className="row portfolio-container"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              {/*
  <div class="col-lg-4 col-md-6 portfolio-item filter-app">
  <div class="portfolio-wrap">
      <img src="/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
      <div class="portfolio-info">
      <h4>Penteado X</h4>
      <p>Atendimento da Estrela Anna</p>
      <div class="portfolio-links">
          <a href="/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Atendimento da Estrela Anna"><i class="bx bx-plus"></i></a>
      </div>
      </div>
  </div>
  </div>

  <div class="col-lg-4 col-md-6 portfolio-item filter-web">
  <div class="portfolio-wrap">
      <img src="/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
      <div class="portfolio-info">
      <h4>Maquiagem x</h4>
      <p>Atendimento da Estrela Julia</p>
      <div class="portfolio-links">
          <a href="/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Atendimento da Estrela Julia"><i class="bx bx-plus"></i></a>
      </div>
      </div>
  </div>
  </div>

  <div class="col-lg-4 col-md-6 portfolio-item filter-app">
  <div class="portfolio-wrap">
      <img src="/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
      <div class="portfolio-info">
      <h4>Corte curto em cabelo liso</h4>
      <p>Atendimento da Estrela Debora</p>
      <div class="portfolio-links">
          <a href="/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Atendimento da Estrela Debora"><i class="bx bx-plus"></i></a>
      </div>
      </div>
  </div>
  </div>*/}
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img
                    src={portifolio4}
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Unha Redonda</h4>
                    <p>Atendimento da Estrela Lu</p>
                    <div className="portfolio-links">
                      {/* <a
                        href={portifolio4}
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Atendimento da Estrela Laura"
                      >
                        <i className="bx bx-plus" />
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              {/*
  <div class="col-lg-4 col-md-6 portfolio-item filter-web">
  <div class="portfolio-wrap">
      <img src="/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
      <div class="portfolio-info">
      <h4>Maquiagem leve para formatura</h4>
      <p>Atendimento da Estrela Julia</p>
      <div class="portfolio-links">
          <a href="/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Atendimento da Estrela Julia"><i class="bx bx-plus"></i></a>
      </div>
      </div>
  </div>
  </div>

  <div class="col-lg-4 col-md-6 portfolio-item filter-app">
  <div class="portfolio-wrap">
      <img src="/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
      <div class="portfolio-info">
      <h4>Escova em cabelo loiro</h4>
      <p>Atendimento da Estrela Fernanda</p>
      <div class="portfolio-links">
          <a href="/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Atendimento da Estrela Fernanda"><i class="bx bx-plus"></i></a>
      </div>
      </div>
  </div>
  </div>

  <div class="col-lg-4 col-md-6 portfolio-item filter-card">
  <div class="portfolio-wrap">
      <img src="/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
      <div class="portfolio-info">
      <h4>Unha gel</h4>
      <p>Atendimento da Estrela Julia</p>
      <div class="portfolio-links">
          <a href="/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Atendimento da Estrela Julia"><i class="bx bx-plus"></i></a>
      </div>
      </div>
  </div>
  </div>

  <div class="col-lg-4 col-md-6 portfolio-item filter-card">
  <div class="portfolio-wrap">
      <img src="/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
      <div class="portfolio-info">
      <h4>Unha x</h4>
      <p>Atendimento da Estrela Maria</p>
      <div class="portfolio-links">
          <a href="/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Atendimento da Estrela Maria"><i class="bx bx-plus"></i></a>
      </div>
      </div>
  </div>
  </div>

  <div class="col-lg-4 col-md-6 portfolio-item filter-web">
  <div class="portfolio-wrap">
      <img src="/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
      <div class="portfolio-info">
      <h4>Maquiagem X</h4>
      <p>Atendimento da Estrela Marta</p>
      <div class="portfolio-links">
          <a href="/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Atendimento da Estrela Marta"><i class="bx bx-plus"></i></a>
      </div>
      </div>
  </div>
  </div>*/}
            </div>
          </div>
        </section>
        {/* End Portfolio Section */}
        {/* ======= About Section ======= */}
        <section id="about" className="about section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="content col-xl-5 d-flex align-items-stretch">
                <div className="content">
                  <h3>Como faço para ser uma Estrela Fiara?</h3>
                  <p>
                    Ser uma Estrela Fiara é ideal para quem busca aumentar seus
                    ganhos enquanto trabalha fazendo seu próprio horário. Nossas
                    Estrelas têm autonomia e liberdade.
                    <br />
                    Hoje atendemos na cidade de Chapecó-SC
                  </p>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSe-fu9NFa9dclxTZnT-EHao9xDLLgQ_rf-xImWn0Ks2IZXsEA/viewform"
                    target="_blank"
                    className="about-btn"
                  >
                    <span>Quero ser uma Estrela</span>{" "}
                    <i className="bx bx-chevron-right" />
                  </a>
                </div>
              </div>
              <div className="col-xl-7 d-flex align-items-stretch">
                <div className="icon-boxes d-flex flex-column justify-content-center">
                  <div className="row">
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <i className="bx bx-receipt" />
                      <h4>1° Cadastro no site</h4>
                      <p>
                        Primeiro passo é se cadastrar aqui em nosso site com seu
                        dados básicos. Pode fazer o cadastro cliacando no botão ao
                        lado "Quero ser uma Estrela"
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <i className="bx bx-cube-alt" />
                      <h4>2° Entrevista</h4>
                      <p>
                        Um pessoa de nossa equipe entrara em contato para agendar
                        uma entrevista. Essa entrevista será um bate papo para
                        conhecer melhor você e suas habilidades.
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <i className="bx bx-images" />
                      <h4>3° Orientações</h4>
                      <p>
                        Nossa equipe vai repassar para você as orientações e
                        diretrizes de funcionamento da Fiara.
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay={400}
                    >
                      <i className="bx bx-shield" />
                      <h4>4° Tudo Pronto</h4>
                      <p>
                        Agora está tudo pronto. Só aguardar as solicitações
                        começarem a chegar para você! 😀
                      </p>
                    </div>
                  </div>
                </div>
                {/* End .content*/}
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
        {/* ======= Tabs Section ======= */}
        <section id="tabs" className="tabs">
          <div className="container" data-aos="fade-up">
            <h3 className="section-title">Por que ser uma Estrela Fiara?</h3>
            <ul className="nav nav-tabs row d-flex">
              <li className="nav-item col-3">
                <a
                  className="nav-link active show"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-1"
                >
                  <i className="ri-home-heart-line" />
                  <h4 className="d-none d-lg-block">Autonomia e flexibilidade</h4>
                </a>
              </li>
              <li className="nav-item col-3">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-2"
                >
                  <i className="ri-money-dollar-box-line" />
                  <h4 className="d-none d-lg-block">
                    Defina o valor seus serviços
                  </h4>
                </a>
              </li>
              <li className="nav-item col-3">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-3"
                >
                  <i className="ri-group-3-line" />
                  <h4 className="d-none d-lg-block">Novo canal de clientes</h4>
                </a>
              </li>
              <li className="nav-item col-3">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-4"
                >
                  <i className="ri-store-line" />
                  <h4 className="d-none d-lg-block">
                    Tenha uma marca forte ao seu lado
                  </h4>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active show" id="tab-1">
                <div className="row">
                  <div
                    className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <h3>Autonomia e flexibilidade para trabalhar quando quiser.</h3>
                    <p className="fst-italic">
                      Trabalhar com autonomia e flexibilidade oferece uma série de
                      benefícios que podem impactar positivamente tanto a vida
                      profissional quanto a pessoal. Esses aspectos têm se tornado
                      cada vez mais valorizados no mercado de trabalho
                      contemporâneo, proporcionando um ambiente propício para o
                      crescimento individual e o alcance de objetivos. Abaixo estão
                      alguns dos principais benefícios dessa abordagem:
                    </p>
                    <ul>
                      <li>
                        <i className="ri-check-double-line" /> Equilíbrio entre vida
                        pessoal e profissional.
                      </li>
                      <li>
                        <i className="ri-check-double-line" /> Maior produtividade e
                        eficiência.
                      </li>
                      <li>
                        <i className="ri-check-double-line" /> Estímulo à
                        criatividade e inovação
                      </li>
                      <li>
                        <i className="ri-check-double-line" /> Desenvolvimento de
                        habilidades de autogestão
                      </li>
                      <li>
                        <i className="ri-check-double-line" /> Redução do estresse e
                        aumento do bem-estar
                      </li>
                      <li>
                        <i className="ri-check-double-line" /> Liberdade de agenda,
                        você faz sua própria agenda!
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 text-center"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <img src="/img/tabs-1.png" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-2">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>
                      Aqui na Fiara, você é livre para definir os valores dos seus
                      serviços
                    </h3>
                    <p>
                      A Fiara preza pela autonomia e liberdade dos profissionais,
                      nossas Estrelas Fiara e, portanto, cada profissional pode
                      definir os valores dos seus serviços:
                    </p>
                    <ul>
                      <li>
                        <i className="ri-check-double-line" /> Transparência: Ao
                        estabelecer valores claros, os clientes sabem exatamente o
                        que esperar em termos de custo, evitando surpresas
                        desagradáveis.
                      </li>
                      <li>
                        <i className="ri-check-double-line" /> Credibilidade: Ter
                        valores bem definidos demonstra profissionalismo e confiança
                        na qualidade do serviço prestado, o que pode atrair clientes
                        em potencial.
                      </li>
                      <li>
                        <i className="ri-check-double-line" /> Competitividade:
                        Valores competitivos ajudam a posicionar o serviço no
                        mercado, destacando-se entre os concorrentes e
                        potencialmente aumentando a demanda.
                      </li>
                      <li>
                        <i className="ri-check-double-line" /> Fidelização de
                        clientes: Clientes satisfeitos com os valores e a qualidade
                        do serviço tendem a retornar e recomendar os serviços a
                        outras pessoas, gerando uma base de clientes fiéis.
                      </li>
                    </ul>
                    <p>
                      Em resumo, definir os valores dos serviços oferece clareza,
                      confiança e competitividade, contribuindo para o sucesso e o
                      crescimento sustentável do negócio.{" "}
                    </p>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img src="/img/tabs-2.png" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-3">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>
                      Seus serviços divulgados e acessíveis para milhares de
                      clientes, sem custo para você!
                    </h3>
                    <p>
                      A Fiara disponibiliza aos profissionais, nossas Estrelas,
                      novos canais para divulgação dos seus serviços. Por meio de
                      nossas redes sociais e site, a Fiara divulga os serviços
                      prestados por nossas Estrelas, visando aumentar a visibilidade
                      das profissionais, gerar maior volume de serviços prestados e,
                      sobretudo, fomentar o aumento da renda das nossas
                      profissionais. E tudo isso sem nenhum custo para as Estrela
                      Fiara!
                    </p>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img src="/img/tabs-3.png" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-4">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>
                      A Fiara já é reconhecida por seu modelo de negócios inovador e
                      você pode fazer parte disso!
                    </h3>
                    <p>
                      A Fiara está se consolidando como referência na entrega de
                      serviços de beleza e bem-estar no formato delivery. Estamos em
                      evidência pelo modelo de negócios altamente escalável e
                      inovador e já fomos selecionados em editais da Fapesc:
                      Mulheres+Tec e Super Pitch Day. Além da Fapesc, temos apoio e
                      fomento do Sebrae e Secretaria da Ciência, Tecnologia e
                      Inovação do Estado de Santa Catarina. E você pode fazer parte
                      disso. Juntos somos mais fortes e podemos ir mais longe!
                    </p>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img src="/img/tabs-4.png" alt="" style={{ maxWidth: "70%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Tabs Section */}
        {/* ======= Testimonials Section ======= */}
        {/*<section id="testimonials" class="testimonials">
    <div class="container" data-aos="fade-up">
    <div class="section-title">
  <h2>Depoimentos</h2>
  <p>Alguns depoimentos de quem já foi antedido por uma Estrela Fiara.</p>
    </div>

    <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
  <div class="swiper-wrapper">

  <div class="swiper-slide">
      <div class="testimonial-wrap">
      <div class="testimonial-item">
          <img src="/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
          <h3>Saul Goodman</h3>
          <h4>Ceo &amp; Founder</h4>
          <p>
          <i class="bx bxs-quote-alt-left quote-icon-left"></i>
          Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
          <i class="bx bxs-quote-alt-right quote-icon-right"></i>
          </p>
      </div>
      </div>
  </div>*/}
        {/* End testimonial item */}
        {/*
  <div class="swiper-slide">
      <div class="testimonial-wrap">
      <div class="testimonial-item">
          <img src="/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
          <h3>Sara Wilsson</h3>
          <h4>Designer</h4>
          <p>
          <i class="bx bxs-quote-alt-left quote-icon-left"></i>
          Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
          <i class="bx bxs-quote-alt-right quote-icon-right"></i>
          </p>
      </div>
      </div>
  </div>

  <div class="swiper-slide">
      <div class="testimonial-wrap">
      <div class="testimonial-item">
          <img src="/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
          <h3>Jena Karlis</h3>
          <h4>Store Owner</h4>
          <p>
          <i class="bx bxs-quote-alt-left quote-icon-left"></i>
          Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
          <i class="bx bxs-quote-alt-right quote-icon-right"></i>
          </p>
      </div>
      </div>
  </div>

  <div class="swiper-slide">
      <div class="testimonial-wrap">
      <div class="testimonial-item">
          <img src="/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
          <h3>Matt Brandon</h3>
          <h4>Freelancer</h4>
          <p>
          <i class="bx bxs-quote-alt-left quote-icon-left"></i>
          Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
          <i class="bx bxs-quote-alt-right quote-icon-right"></i>
          </p>
      </div>
      </div>
  </div>

  <div class="swiper-slide">
      <div class="testimonial-wrap">
      <div class="testimonial-item">
          <img src="/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
          <h3>John Larson</h3>
          <h4>Entrepreneur</h4>
          <p>
          <i class="bx bxs-quote-alt-left quote-icon-left"></i>
          Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat esse veniam culpa fore nisi cillum quid.
          <i class="bx bxs-quote-alt-right quote-icon-right"></i>
          </p>
      </div>
      </div>
  </div>*/}
        <div className="swiper-pagination" />
        {/* End Testimonials Section */}
        {/* ======= Pricing Section ======= */}
        {/*<section id="pricing" class="pricing section-bg">
    <div class="container" data-aos="fade-up">

    <div class="section-title">
  <h2>Pricing</h2>
  <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.</p>
    </div>

    <div class="row">

  <div class="col-lg-4 col-md-6">
  <div class="box" data-aos="fade-up" data-aos-delay="100">
      <h3>Free</h3>
      <h4><sup>$</sup>0<span> / month</span></h4>
      <ul>
      <li>Aida dere</li>
      <li>Nec feugiat nisl</li>
      <li>Nulla at volutpat dola</li>
      <li class="na">Pharetra massa</li>
      <li class="na">Massa ultricies mi</li>
      </ul>
      <div class="btn-wrap">
      <a href="#" class="btn-buy">Buy Now</a>
      </div>
  </div>
  </div>

  <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
  <div class="box featured" data-aos="fade-up" data-aos-delay="200">
      <h3>Business</h3>
      <h4><sup>$</sup>19<span> / month</span></h4>
      <ul>
      <li>Aida dere</li>
      <li>Nec feugiat nisl</li>
      <li>Nulla at volutpat dola</li>
      <li>Pharetra massa</li>
      <li class="na">Massa ultricies mi</li>
      </ul>
      <div class="btn-wrap">
      <a href="#" class="btn-buy">Buy Now</a>
      </div>
  </div>
  </div>

  <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
  <div class="box" data-aos="fade-up" data-aos-delay="300">
      <h3>Developer</h3>
      <h4><sup>$</sup>29<span> / month</span></h4>
      <ul>
      <li>Aida dere</li>
      <li>Nec feugiat nisl</li>
      <li>Nulla at volutpat dola</li>
      <li>Pharetra massa</li>
      <li>Massa ultricies mi</li>
      </ul>
      <div class="btn-wrap">
      <a href="#" class="btn-buy">Buy Now</a>
      </div>
  </div>
  </div>

    </div>

    </div>
</section> */}
        {/* End Pricing Section */}
        {/* ======= Frequently Asked Questions Section ======= */}
        <section id="faq" className="faq">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Perguntas Frequentes</h2>
            </div>
            <ul className="faq-list accordion" data-aos="fade-up">
              <li>
                <a
                  data-bs-toggle="collapse"
                  className="collapsed"
                  data-bs-target="#faq1"
                >
                  Como faço para agendar um atendimento?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Entre em contato conosco pelo WhatsApp (49)98904-8131 ou pelo
                    nosso site. Escolha o serviço, indique o data e horário e
                    aguarde confirmação da disponibilidade de profissional.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq2"
                  className="collapsed"
                >
                  Preciso ter um kit para atendimento com
                  manicure/pedicure/podóloga?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Sim, ter um kit básico é essencial para receber os serviços de
                    manicure, pedicure e podologia de qualidade e com segurança
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq3"
                  className="collapsed"
                >
                  Qual horário para agendamento dos serviços?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Você pode fazer contato conosco entre 8h às 18h para agendar seu
                    atendimento. Os atendimentos podem ser realizados entre 6h30 às
                    22h, durante os sete dias da semana.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq4"
                  className="collapsed"
                >
                  Como saber se o profissional é mesmo uma Estrela Fiara?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Você receberá a confirmação do seu agendamento contendo uma foto
                    da profissional que irá lhe atender.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq5"
                  className="collapsed"
                >
                  Há um intervalo mínimo entre solicitação do serviço e realização
                  do atendimento? <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Sim, um intervalo mínimo de 4 horas, necessário para garantir
                    tempo suficiente para o profissional se preparar, organizar seus
                    compromissos e chegar ao local do atendimento. Isso permite uma
                    gestão mais eficiente do tempo e garante que o serviço seja
                    realizado com qualidade e pontualidade. Lembrando que nosso
                    horário de agendamento é das 8h às 18h.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq6"
                  className="collapsed"
                >
                  Como faço para agendar um atendimento?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Sim, um intervalo mínimo de 4 horas, necessário para garantir
                    tempo suficiente para o profissional se preparar, organizar seus
                    compromissos e chegar ao local do atendimento. Isso permite uma
                    gestão mais eficiente do tempo e garante que o serviço seja
                    realizado com qualidade e pontualidade. Lembrando que nosso
                    horário de agendamento é das 8h às 18h.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq7"
                  className="collapsed"
                >
                  Posso agendar serviço recorrente (agenda fixa semanal, mensal)?
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Sim! Entre em contato conosco que deixamos os serviços agendados
                    para as datas que você tiver interesse.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq8"
                  className="collapsed"
                >
                  Sou alérgico ou...
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq8" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Informe a Fiara em mensagem de texto durante o seu agendamento.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq9"
                  className="collapsed"
                >
                  Preciso disponibilizar uma maca de massagem?
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq9" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Não é necessário, fica a seu critério. As Estrelas Fiara levam a
                    maca de massagem, assim como todos os produtos e itens
                    necessários. Salientamos que durante a realização desse serviço
                    é obrigatório permanecer com roupas íntimas.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq10"
                  className="collapsed"
                >
                  Como é realizado o processo de cobrança?
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq10" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Você fará o pagamento via pix ou cartão de crédito diretamente
                    para a Fiara previamente a confirmação do seu agendamento.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq11"
                  className="collapsed"
                >
                  Posso agendar mais de um serviço com o mesmo profissional na mesma
                  compra? Posso agendar serviços consecutivos com profissionais
                  diferentes? Posso alterar meu endereço após ter confirmado o
                  agendamento? <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq11" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Com certeza! Basta fazer a solicitação pelo WhatsApp da Fiara e
                    aguardar confirmação da nossa equipe sobre a disponibilidade dos
                    profissionais.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq12"
                  className="collapsed"
                >
                  Posso alterar o horário do atendimento após a confirmação?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq12" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    As solicitações de alteração de horário levam em consideração a
                    disponibilidade das profissionais, respeitando sempre o
                    intervalo mínimo de 4 horas entre solicitação e atendimento.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq13"
                  className="collapsed"
                >
                  Posso cancelar meu agendamento?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq13" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Caso precise cancelar um agendamento, o valor pago será
                    revertido em crédito para um agendamento posterior. Caso o
                    cancelamento seja realizado em menos de 1 hora antes do
                    atendimento, a cobrança do serviço será mantida e nenhum valor
                    será revertido como crédito.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq14"
                  className="collapsed"
                >
                  Posso fazer agendamentos diretamente com a profissional por outros
                  canais (telefone particular, redes sociais ou outros meios), sem
                  que haja intermédio da Fiara?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq14" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Não. Qualquer agendamento feito diretamente com a Estrela Fiara
                    leva a exclusão do perfil da profissional e o encerramento da
                    parceria com a Fiara. Para saber mais, acesse nossos termos de
                    uso.
                  </p>
                  <p>
                    Seguindo o que estabelece a Lei Geral de Proteção de Dados (Lei
                    nº 13.709/2018), as comunicações entre clientes e profissionais
                    devem ser feitas exclusivamente por intermediação da Fiara.
                    Assim conseguimos garantir uma experiência de qualidade e segura
                    para clientes e profissionais.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq15"
                  className="collapsed"
                >
                  Por que o mesmo serviço pode ter valores diferentes?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-x icon-close" />
                </a>
                <div id="faq15" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    Na Fiara, cada profissional tem autonomia e liberdade para
                    definir o valor dos seus serviços. Não estabelecemos valores
                    fixos e as Estrelas Fiara podem definir e alterar os valores
                    livremente.
                  </p>
                  <p>
                    Mas você sempre saberá o valor do serviço antes da confirmação
                    do agendamento e esse valor NÃO sofrerá alteração.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        {/* End Frequently Asked Questions Section */}
        {/* ======= Team Section ======= */}
        {/*<section id="team" class="team section-bg"> 
    <div class="container" data-aos="fade-up">

    <div class="section-title">
  <h2>Team</h2>
  <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.</p>
    </div>

    <div class="row">

  <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
  <div class="member" data-aos="fade-up" data-aos-delay="100">
      <div class="member-img">
      <img src="/img/team/team-1.jpg" class="img-fluid" alt="">
      <div class="social">
          <a href=""><i class="bi bi-twitter"></i></a>
          <a href=""><i class="bi bi-facebook"></i></a>
          <a href=""><i class="bi bi-instagram"></i></a>
          <a href=""><i class="bi bi-linkedin"></i></a>
      </div>
      </div>
      <div class="member-info">
      <h4>Walter White</h4>
      <span>Chief Executive Officer</span>
      </div>
  </div>
  </div>

  <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
  <div class="member" data-aos="fade-up" data-aos-delay="200">
      <div class="member-img">
      <img src="/img/team/team-2.jpg" class="img-fluid" alt="">
      <div class="social">
          <a href=""><i class="bi bi-twitter"></i></a>
          <a href=""><i class="bi bi-facebook"></i></a>
          <a href=""><i class="bi bi-instagram"></i></a>
          <a href=""><i class="bi bi-linkedin"></i></a>
      </div>
      </div>
      <div class="member-info">
      <h4>Sarah Jhonson</h4>
      <span>Product Manager</span>
      </div>
  </div>
  </div>

  <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
  <div class="member" data-aos="fade-up" data-aos-delay="300">
      <div class="member-img">
      <img src="/img/team/team-3.jpg" class="img-fluid" alt="">
      <div class="social">
          <a href=""><i class="bi bi-twitter"></i></a>
          <a href=""><i class="bi bi-facebook"></i></a>
          <a href=""><i class="bi bi-instagram"></i></a>
          <a href=""><i class="bi bi-linkedin"></i></a>
      </div>
      </div>
      <div class="member-info">
      <h4>William Anderson</h4>
      <span>CTO</span>
      </div>
  </div>
  </div>

  <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
  <div class="member" data-aos="fade-up" data-aos-delay="400">
      <div class="member-img">
      <img src="/img/team/team-4.jpg" class="img-fluid" alt="">
      <div class="social">
          <a href=""><i class="bi bi-twitter"></i></a>
          <a href=""><i class="bi bi-facebook"></i></a>
          <a href=""><i class="bi bi-instagram"></i></a>
          <a href=""><i class="bi bi-linkedin"></i></a>
      </div>
      </div>
      <div class="member-info">
      <h4>Amanda Jepson</h4>
      <span>Accountant</span>
      </div>
  </div>
  </div>

    </div>

    </div>
</section>*/}
        {/* End Team Section */}
        {/* ======= Clients Section ======= */}
        <section id="clients" className="clients section-bg">
          <div className="container" data-aos="zoom-in">
            <div className="section-title">
              <h2>Apoiadores e Parceiros</h2>
              <img
                src={parceiro1}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
              <img
                src={parceiro2}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
              <img
                src={parceiro3}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
              <img
                src={parceiro4}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
              <img
                src={parceiro5}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
              <img
                src={parceiro6}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
              <img
                src={parceiro7}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
              <img
                src={parceiro8}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
              <img
                src={parceiro9}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
              <img
                src={parceiro10}
                className="img-fluid"
                style={{ maxWidth: 160, margin: 10 }}
                alt=""
              />
            </div> {/*
            <div className="clients-slider swiper">
              <div className="swiper-wrapper align-items-center">
                <div className="swiper-slide">
                  <img
                    src={parceiro1}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={parceiro2}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={parceiro3}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={parceiro4}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={parceiro5}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={parceiro6}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={parceiro7}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={parceiro8}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={parceiro9}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={parceiro10}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="swiper-pagination" />
            </div> */}
          </div>
        </section>
        {/* End Clients Section */}
        {/* ======= Contact Section ======= */}
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contato</h2>
              <p>
                Alguma dúvida, sujestão, reclamação ou elogio? Entre em contato
                conosco.
              </p>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay={100}>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="info-box">
                      <i className="bx bx-map" />
                      <h3>Nosso endereço</h3>
                      <p>Chapecó - SC</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-envelope" />
                      <h3>Email</h3>
                      <p>fiarabelezaebemestar@gmail.com</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-phone-call" />
                      <h3>Telefone/Whatsapp</h3>
                      <p>+55 (49) 98904-8131</p>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div class="col-lg-6">
  <form action="forms/contact.php" method="post" role="form" class="php-email-form">
      <div class="row">
      <div class="col form-group">
          <input type="text" name="name" class="form-control" id="name" placeholder="Seu nome" required>
      </div>
      <div class="col form-group">
          <input type="email" class="form-control" name="email" id="email" placeholder="Seu Email" required>
      </div>
      </div>
      <div class="form-group">
      <input type="text" class="form-control" name="subject" id="subject" placeholder="Assunto" required>
      </div>
      <div class="form-group">
      <textarea class="form-control" name="message" rows="5" placeholder="Mensagem" required></textarea>
      </div>
      <div class="my-3">
      <div class="loading">Enviando..</div>
      <div class="error-message"></div>
      <div class="sent-message">Sua mensagem foi enviada. Obrigado!</div>
      </div>
      <div class="text-center"><button type="submit">Enviar</button></div>
  </form>
  </div>*/}
            </div>
          </div>
        </section>
        {/* End Contact Section */}
      </main>
      {/* End #main */}
      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>
                  FIara<span>.</span>
                </h3>
                <p>
                  Chapecó <br />
                  Santa Catarina
                  <br />
                  Brasil <br />
                  <br />
                  <strong>Telefone:</strong> +55 (49) 98904-8131
                  <br />
                  <strong>Email:</strong> fiarabelezaebemestar@gmail.com
                  <br />
                </p>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" /> <a href="#">Início</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" /> <a href="#">Serviços</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/termos-de-uso.pdf" target="_blank">
                      Política de Privacidade e Termos de Uso
                    </a>
                  </li>
                </ul>
              </div>
              {/*<div class="col-lg-4 col-md-6 footer-newsletter">
  <h4>Nossa Newsletter</h4>
  <p>Se inscreva e fique por dentro de todas as novidades exclusivas e em primeira mão da Fiara</p>
  <form action="" method="post">
      <input type="email" name="email"><input type="submit" value="Subscribe">
  </form>
  </div>*/}
            </div>
          </div>
        </div>
        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              © Copyright{" "}
              <strong>
                <span>Fiara</span>
              </strong>
              . Todos os direitos reservados
            </div>
            <div className="credits">
              {/* All the links in the footer should remain intact. */}
              {/* You can delete the links only if you purchased the pro version. */}
              {/* Licensing information: https://bootstrapmade.com/license/ */}
              {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/FIara-bootstrap-corporate-template/ */}
              Desenvolvido por{" "}
              <strong>
                <span>Dolh Soluções em Tecnologia LTDA</span>
              </strong>
            </div>
          </div>
          <div className="social-links text-center text-md-end pt-3 pt-md-0">
            <a
              href="https://wa.me/5549989048131"
              target="_blank"
              className="whatsapp"
            >
              <i className="bx bxl-whatsapp" />
            </a>
            <a
              href="https://www.instagram.com/fiarabelezaebemestar?igsh=MW80YXBiMDhxMTA5ag=="
              target="_blank"
              className="instagram"
            >
              <i className="bx bxl-instagram" />
            </a>
            <a
              href="https://wa.me/5549989048131"
              target="_blank"
              className="linkedin"
            >
              <i className="bx bxl-linkedin" />
            </a>
          </div>
        </div>
      </footer>
      {/* End Footer */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>

    </>

  );
}

export default App;
